.nav {
}

.container {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0px 10px;
}

.element {
  float: left;
}

.link {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  text-align: center;
  display: block;
  text-decoration: none;
  margin: 60px 40px
}

.link:hover {
  text-decoration: underline;
  color: red;
}

.button {
  font-family: "Roboto Mono";
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  text-align: center;
  display: block;
  text-decoration: none;
  margin: 60px 40px;
  padding: 0px;
  background: none;
  border: none;
  cursor: pointer;
}

.button:hover {
  text-decoration: underline;
  color: red;
}
