.about {
  width: 800px;
  margin: 0px 90px;
}

.heading {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 53px;
  color: #000000;
}

.callout {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.strike {
  text-decoration-line: line-through;
}

.italic {
  font-style: italic;
}

.list {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.list li {
  margin: 20px 0px;
}