.modal {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.hidden {
  display: none;
}

.container {
  background: #ffffff;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 60px 80px;
}
