.button {
  opacity: 0;
  background: none;
  border: none;
  padding: 0px;
  margin-left: 15px;
  cursor: pointer;
  pointer-events: none;
}

.show {
  opacity: 1;
  pointer-events: all;
}

.edit {
  width: 10px;
  height: 10px;
}