
.header {
  width: 540px;
  border: 7px solid #000000;
  box-sizing: border-box;
  display: flex;
  padding: 0px 30px;
  align-items: center;
  background-color: #f8f8f8;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}

.new {
  font-family: "Roboto Mono";
  margin-left: auto;
  display: block;
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  border: none;
  text-decoration: none;
  border-left: solid 7px black;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  background: none;
  cursor: pointer;
}

.new:hover {
  color: #0000EE;
}