.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  margin: 80px 0px;
}

.container {
  margin: auto;
}

.text {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  width: 216px;
  color: #a4a4a4;
}

.italic {
  font-style: italic;
  font-weight: 700;
}
